const aws = require('aws-sdk');

aws.config.update({
    apiVersion: "2006-03-01",
    accessKeyId: process.env.VUE_APP_AWS_ACCESS_KEY_ID,
    secretAccessKey: process.env.VUE_APP_AWS_SECRET_ACCESS_KEY,
    region: process.env.VUE_APP_AWS_DEFAULT_REGION,
});

const s3 = new aws.S3({
    params : {
        Bucket: process.env.VUE_APP_AWS_BUCKET, 
        Body : {}
    }
});


export default s3;